import { createAuthProvider } from 'react-token-auth';
import { SERVER_DOWN_ERROR } from './constants';

const { REACT_APP_API_URL } = process.env;

export const [useAuth, authFetch, login, logout] = createAuthProvider({
  accessTokenKey: 'access_token',
  onUpdateToken: (token) => fetch(`${REACT_APP_API_URL}/auth/refresh`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token.refresh_token}`,
    },
  })
    .then((r) => r.json())
    .then((r) => r.data),
});

export function sendRequest({ route, method, body = {}, withAuth = true, onSuccess = null, onError = null }) {
  let url = `${REACT_APP_API_URL}/${route}`;

  const params = { method, headers: { 'Content-Type': 'application/json' } };

  if (method === 'GET') {
    url += `?${new URLSearchParams(body)}`;
  } else {
    params.body = JSON.stringify(body);
  }

  const fetchFun = withAuth ? authFetch : fetch;

  return fetchFun(url, params)
    .then((r) => r.text())
    .then((text) => {
      try {
        const response = JSON.parse(text);
        return response;
      } catch (err) {
        // This usually happens when the server can't process the request for some reason
        throw Error('Our API is down. Please try again later.');
      }
    })
    .then((response) => {
      if ([200, 201].includes(response.status_code)) {
        if (onSuccess) { onSuccess(response.data, response.message); }
        return response;
      }

      const error = response.message;
      if (onError) { onError(error); } else { throw Error(error); }
      return null;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error);
      if (onError) { onError(SERVER_DOWN_ERROR); } else { throw Error(SERVER_DOWN_ERROR); }
      return null;
    });
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isLoading: true,
        isError: false,
        data: action.payload || state.data,
        message: '',
        error: '',
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload || state.data,
        message: action.message,
        error: '',
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
        data: action.payload || state.data,
        message: '',
        error: action.error,
      };
    default:
      throw new Error();
  }
};

export function serializeForm(form, multipleSelects = []) {
  // This is a helper function to serialize form data, including multiple select values
  const formData = new FormData(form);
  const obj = {};

  formData.forEach((value, key) => {
    if (multipleSelects.includes(key)) {
      if (!obj[key]) {
        obj[key] = [];
      }
      obj[key].push(value);
    } else {
      obj[key] = value;
    }
  });
  return obj;
}
