import React from 'react';
import { Alert, Button, Col, Form, Row, Spinner, Table } from 'react-bootstrap';
import { useUser } from '../../hooks';
import { reducer, sendRequest } from '../../utils';

function WebauthnCredentials() {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isLoading: false, isError: false,
    },
  );

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest({
      route: 'auth/webauthn/credentials',
      method: 'GET',
      onSuccess: (data) => {
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      },
      onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
    });
  }, []);

  const handleWebauthnSetup = () => {
    console.log('setup webauthn');

    sendRequest({
      route: 'auth/webauthn/register/start',
      method: 'GET',
    }).then(async (response) => {
      const options = response.data;

      options.challenge = Uint8Array.from(window.atob(options.challenge.replace(/-/g, '+').replace(/_/g, '/')), (c) => c.charCodeAt(0));
      options.user.id = Uint8Array.from(window.atob(options.user.id), (c) => c.charCodeAt(0));

      // exclude_credentials
      options.excludeCredentials = options.excludeCredentials.map((listItem) => {
        listItem.id = Uint8Array.from(window.atob(listItem.id.replace(/-/g, '+').replace(/_/g, '/')), (c) => c.charCodeAt(0));
        return listItem;
      });

      const credential = await navigator.credentials.create({ publicKey: options });

      const data = {
        id: credential.id,
        rawId: window.btoa(String.fromCharCode.apply(null, new Uint8Array(credential.rawId))),
        response: {
          clientDataJSON: window.btoa(String.fromCharCode.apply(null, new Uint8Array(credential.response.clientDataJSON))),
          attestationObject: window.btoa(String.fromCharCode.apply(null, new Uint8Array(credential.response.attestationObject))),
        },
      };

      sendRequest({
        route: 'auth/webauthn/register/finish',
        method: 'POST',
        body: data,
        onSuccess: (data) => {
          if (data && data.verified) {
            dispatch({ type: 'FETCH_SUCCESS', message: 'Webauthn registered successfully' });
          } else {
            dispatch({ type: 'FETCH_FAILURE', error: 'Webauthn registration failed' });
          }
        },
        onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
      });
    })
      .catch((error) => {
        if (error.name === 'InvalidStateError') {
          dispatch({ type: 'FETCH_FAILURE', error: 'The authenticator was previously registered' });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: String(error) });
        }
      });
  };

  const handleDeleteClick = (id) => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest({
      route: `auth/webauthn/credentials/${id}`,
      method: 'DELETE',
      onSuccess: () => {
        const data = state.data.filter((credential) => credential.id !== id);
        dispatch({
          type: 'FETCH_SUCCESS',
          message: 'Webauthn credential deleted successfully',
          payload: data,
        });
      },
      onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
    });
  };

  return (
    <Row>
      <h3 className="d-flex justify-content-between align-items-center mb-4">
        Webauthn Credentials
        {state.isLoading && <Spinner animation="border" />}
      </h3>

      {state.isError && (
        <Alert variant="danger">
          {state.error}
        </Alert>
      )}

      {state.message && (
        <Alert variant="success">
          {state.message}
        </Alert>
      )}

      <Button
        variant="outline-primary"
        type="button"
        className="mb-3"
        onClick={handleWebauthnSetup}
      >
        Setup Webauthn
      </Button>

      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Credential ID</th>
            <th>Sign Count</th>
            <th>Created At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {
            state.data && state.data.map((credential) => (
              <tr key={credential.id}>
                <td>{credential.credential_id}</td>
                <td>{credential.sign_count}</td>
                <td>{credential.createdAt}</td>
                <td>
                  <Button variant="danger" size="sm" onClick={() => handleDeleteClick(credential.id)}>Delete</Button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>

      <small>
        Please note that Sign Count for some authenticators may not be updated
      </small>
    </Row>

  );
}

function Profile() {
  const user = useUser();

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: {}, message: '', error: '', isLoading: false, isError: false,
    },
  );

  const handleFormSubmit = (e) => {
    e.preventDefault();

    dispatch({ type: 'FETCH_INIT' });

    // Get form content
    const formData = new FormData(e.target);
    const opts = Object.fromEntries(formData);

    // Remove password if empty
    if (!opts.password) {
      delete opts.password;
    }

    sendRequest({
      route: 'auth/user',
      method: 'PUT',
      body: opts,
      onSuccess: (data) => {
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      },
      onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
    });
  };

  React.useEffect(() => {
    if (user) {
      dispatch({ type: 'FETCH_SUCCESS', payload: user });
    }
  }, [user]);

  if (!user) {
    return null;
  }

  return (
    <Row className="mb-4">
      <h3 className="d-flex justify-content-between align-items-center mb-4">
        Profile
        {state.isLoading && <Spinner animation="border" />}
      </h3>

      {state.isError && (
        <Alert variant="danger">
          {state.error}
        </Alert>
      )}

      <Form onSubmit={handleFormSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} sm="12" md="6" className="mb-3" controlId="first_name">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              name="first_name"
              placeholder="Enter first name"
              defaultValue={state.data.first_name}
              required
            />
          </Form.Group>

          <Form.Group as={Col} sm="12" md="6" className="mb-3" controlId="last_name">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              name="last_name"
              placeholder="Enter last name"
              defaultValue={state.data.last_name}
              required
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} sm="12" md="6" className="mb-3" controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="Enter email"
              defaultValue={state.data.email}
              required
            />
          </Form.Group>

          <Form.Group as={Col} sm="12" md="6" className="mb-3" controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              placeholder="Enter new password"
              defaultValue={state.data.password}
            />
            <Form.Text className="text-muted">
              Leave blank to keep current password
            </Form.Text>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <WebauthnCredentials />
        </Row>

        <Row>
          <Button variant="primary" type="submit">Save</Button>
        </Row>
      </Form>
    </Row>
  );
}

export default Profile;
