import moment from 'moment';
import React from 'react';
import { Alert, Spinner, Row, Table, Badge, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { reducer, sendRequest } from '../../../utils';

function ProductsList() {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isLoading: true, isError: false,
    },
  );

  const handleDeleteProduct = (productId) => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest({
      route: `products/${productId}`,
      method: 'DELETE',
      onSuccess: (_, message) => {
        const products = state.data.filter((product) => product.id !== productId);
        dispatch({ type: 'FETCH_SUCCESS', payload: products, message });
      },
      onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
    });
  };

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest({
      route: 'products',
      method: 'GET',
      onSuccess: (products) => {
        dispatch({ type: 'FETCH_SUCCESS', payload: products });
      },
      onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
    });
  }, []);

  return (
    <Row className="mb-4">
      <h3 className="d-flex justify-content-between align-items-center mb-4">
        Existing products
        {state.isLoading && <Spinner animation="border" />}
      </h3>

      <Button as={NavLink} to="new" variant="primary" className="mb-4">Create a new product</Button>

      {state.isError && (
        <Alert variant="danger" style={{ whiteSpace: 'pre-wrap' }}>
          {state.error}
        </Alert>
      )}

      {state.message && (
        <Alert variant="success">
          {state.message}
        </Alert>
      )}

      {
        state.data.length
          ? (
            <Table className="text-center" bordered>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>Name</th>
                  <th>Repository</th>
                  <th>Description</th>
                  <th>Supported Versions</th>
                  <th>Latest Release Dates</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  state.data.map((product) => (
                    <tr key={product.id}>
                      <td>
                        {
                          product.image
                            ? <img src={product.image} alt={product.name} style={{ maxWidth: '50px' }} />
                            : <span className="text-muted">No image</span>
                        }
                      </td>
                      <td><strong>{product.name}</strong></td>
                      <td>{product.repository}</td>
                      <td>
                        <div style={{ maxWidth: '400px' }}>
                          {product.description}
                        </div>
                      </td>
                      <td>{product.versions.map((version) => <Badge bg="success" className="me-2" key={version}>{version}</Badge>)}</td>
                      <td>
                        {
                          product.latest_release_dates && Object.entries(product.latest_release_dates).map(([version, releaseDate]) => (
                            <Badge bg="success" className="me-2" key={version}>
                              {version}
                              :
                              {' '}
                              {moment(releaseDate).format('DD/MM/YYYY HH:mm')}
                            </Badge>
                          ))
                        }
                      </td>
                      <td>
                        <Button
                          as={NavLink}
                          variant="outline-secondary"
                          className="me-2 mb-2"
                          to={`${product.id}`}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="outline-danger"
                          className="me-2 mb-2"
                          onClick={() => handleDeleteProduct(product.id)}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          )
          : (
            <Alert variant="info">
              No products found
            </Alert>
          )
      }
    </Row>
  );
}

export default ProductsList;
