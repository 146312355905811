import React from 'react';
import { Alert, Button, Row, Spinner, Table } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { BsPersonSquare, BsCheckSquare, BsSquare } from 'react-icons/bs';

import { reducer, sendRequest } from '../../../utils';

function CustomersList() {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isLoading: true, isError: false,
    },
  );

  const handleDeleteCustomer = (customerId) => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest({
      route: `customers/${customerId}`,
      method: 'DELETE',
      onSuccess: (response, message) => {
        const customers = state.data.filter((customer) => customer.id !== customerId);
        dispatch({ type: 'FETCH_SUCCESS', payload: customers, message });
      },
      onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
    });
  };

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest({
      route: 'customers',
      method: 'GET',
      onSuccess: (customers) => {
        dispatch({ type: 'FETCH_SUCCESS', payload: customers });
      },
      onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
    });
  }, []);

  return (
    <Row className="mb-4">
      <h3 className="d-flex justify-content-between align-items-center mb-4">
        Existing customers
        {state.isLoading && <Spinner animation="border" />}
      </h3>

      <Button as={NavLink} to="new" variant="primary" className="mb-4">Create a new customer</Button>

      {state.isError && (
        <Alert variant="danger" style={{ whiteSpace: 'pre-wrap' }}>
          {state.error}
        </Alert>
      )}

      {state.message && (
        <Alert variant="success">
          {state.message}
        </Alert>
      )}

      {
        state.data.length
          ? (
            <Table className="text-center" bordered>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>Name</th>
                  <th>
                    Is Customer / Is Partner
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  state.data.map((customer) => (
                    <tr key={customer.id}>
                      <td>
                        {
                          customer.avatar
                            ? (
                              <img
                                src={customer.avatar}
                                alt="Avatar"
                                style={{ maxWidth: '50px', maxHeight: '50px' }}
                              />
                            )
                            : (
                              <BsPersonSquare size="25" />
                            )
                        }
                      </td>
                      <td>
                        <h5 className="pb-1">
                          {customer.company_name}
                        </h5>
                        <strong>{customer.name}</strong>
                        {` (${customer.email})`}
                        <div className="pt-2">
                          <pre className="mb-0">{customer.repository}</pre>
                          <pre className="mb-0">{customer.odoo_url}</pre>
                        </div>
                      </td>
                      <td>
                        <span className="pe-2">
                          {
                          customer.is_customer
                            ? <BsCheckSquare size="25" />
                            : <BsSquare size="25" />
                          }
                        </span>
                        <span>
                          {
                          customer.is_selling_partner
                            ? <BsCheckSquare size="25" />
                            : <BsSquare size="25" />
                          }
                        </span>
                      </td>
                      <td>
                        {
                          customer.odoo_url && (
                            <Button
                              as={NavLink}
                              to={`${customer.id}/odoo`}
                              variant="outline-primary"
                              className="me-2 mb-2"
                            >
                              Odoo
                            </Button>
                          )
                        }
                        <Button
                          as={NavLink}
                          to={`${customer.id}`}
                          variant="outline-primary"
                          className="me-2 mb-2"
                        >
                          Edit
                        </Button>
                        <Button
                          variant="outline-danger"
                          onClick={() => handleDeleteCustomer(customer.id)}
                          className="me-2 mb-2"
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          )
          : (
            <Alert variant="info">
              No customers found
            </Alert>
          )
      }
    </Row>
  );
}

export default CustomersList;
