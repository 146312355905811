import React from 'react';
import { Alert, Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { reducer, sendRequest } from '../../../utils';
import CustomerSelector from '../Components/CustomerSelector';

function CreateOrEditBranch() {
  const { branchId } = useParams();
  const navigation = useNavigate();

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: {}, message: '', error: '', isLoading: false, isError: false,
    },
  );

  const handleFormSubmit = (e) => {
    e.preventDefault();

    dispatch({ type: 'FETCH_INIT' });

    // Get form content
    const formData = new FormData(e.target);
    const opts = Object.fromEntries(formData);

    const route = branchId ? `branches/${branchId}` : 'branches';
    const method = branchId ? 'PUT' : 'POST';

    // Add avatar to form data
    if (state.data.avatar) {
      opts.avatar = state.data.avatar;
    }

    sendRequest({
      route,
      method,
      body: opts,
      onSuccess: (branch) => {
        dispatch({ type: 'FETCH_SUCCESS', payload: branch });
        navigation('/admin/branches');
      },
      onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
    });
  };

  React.useEffect(() => {
    if (branchId) {
      dispatch({ type: 'FETCH_INIT' });

      sendRequest({
        route: `branches/${branchId}`,
        method: 'GET',
        onSuccess: (branch) => {
          dispatch({ type: 'FETCH_SUCCESS', payload: branch });
        },
        onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
      });
    }
  }, [branchId]);

  return (
    <Row className="mb-4">
      <h3 className="d-flex justify-content-between align-items-center mb-4">
        {branchId ? 'Edit branch' : 'Add new branch'}
        {state.isLoading && <Spinner animation="border" />}
      </h3>

      {state.isError && (
        <Alert variant="danger">
          {state.error}
        </Alert>
      )}

      {state.message && (
        <Alert variant="success">
          {state.message}
        </Alert>
      )}

      <Form onSubmit={handleFormSubmit}>
        <h4 className="mb-4">General</h4>
        <Row className="mb-3">
          <Col sm="12" md="4">
            <CustomerSelector
              name="customer_id"
              label="Customer"
              defaultValue={state.data.customer_id}
              required
            />
          </Col>

          <Col sm="12" md="4">
            <Form.Group as={Col} className="mb-3" controlId="name">
              <Form.Label>Branch Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Enter branch name"
                defaultValue={state.data.name}
                required
              />
            </Form.Group>
          </Col>

          <Col sm="12" md="4">
            <Form.Group as={Col} className="mb-3" controlId="main_branch">
              <Form.Label>Main Branch</Form.Label>
              <Form.Control
                type="text"
                name="main_branch"
                placeholder="Enter main branch"
                defaultValue={state.data.main_branch}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col sm="12" md="12">
            <Form.Group as={Col} className="mb-3" controlId="notes">
              <Form.Label>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="notes"
                placeholder="Enter notes"
                defaultValue={state.data.notes}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Button variant="primary" type="submit">Save</Button>
        </Row>
      </Form>
    </Row>
  );
}

export default CreateOrEditBranch;
