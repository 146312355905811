import React from 'react';
import { Alert, Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import ImageUploader from '../../../Common/ImageUploader';
import { reducer, sendRequest } from '../../../utils';

function CreateOrEditCustomer() {
  const { customerId } = useParams();
  const navigation = useNavigate();

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: {}, message: '', error: '', isLoading: false, isError: false,
    },
  );

  const handleFormSubmit = (e) => {
    e.preventDefault();

    dispatch({ type: 'FETCH_INIT' });

    // Get form content
    const formData = new FormData(e.target);
    const opts = Object.fromEntries(formData);

    const route = customerId ? `customers/${customerId}` : 'customers';
    const method = customerId ? 'PUT' : 'POST';

    // Add avatar to form data
    if (state.data.avatar) {
      opts.avatar = state.data.avatar;
    }

    sendRequest({
      route,
      method,
      body: opts,
      onSuccess: (customer) => {
        dispatch({ type: 'FETCH_SUCCESS', payload: customer });
        navigation('/admin/customers');
      },
      onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
    });
  };

  const handleAvatarChange = (avatar) => {
    dispatch({ type: 'FETCH_SUCCESS', payload: { ...state.data, avatar } });
  };

  React.useEffect(() => {
    if (customerId) {
      dispatch({ type: 'FETCH_INIT' });

      sendRequest({
        route: `customers/${customerId}`,
        method: 'GET',
        onSuccess: (customer) => {
          dispatch({ type: 'FETCH_SUCCESS', payload: customer });
        },
        onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
      });
    }
  }, [customerId]);

  return (
    <Row className="mb-4">
      <h3 className="d-flex justify-content-between align-items-center mb-4">
        {customerId ? 'Edit customer' : 'Add new customer'}
        {state.isLoading && <Spinner animation="border" />}
      </h3>

      {state.isError && (
        <Alert variant="danger">
          {state.error}
        </Alert>
      )}

      {state.message && (
        <Alert variant="success">
          {state.message}
        </Alert>
      )}

      <Form onSubmit={handleFormSubmit}>
        <Row>
          <h4 className="mb-4">General</h4>

          <Col sm="12" md="3">
            <Form.Group as={Col} className="d-flex justify-content-center" controlId="avatar">
              <ImageUploader onImageUpload={handleAvatarChange} defaultImage={state.data.avatar} />
            </Form.Group>
          </Col>

          <Col sm="12" md="9">
            <Form.Group as={Col} className="mb-3" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Enter name"
                defaultValue={state.data.name}
                required
              />
            </Form.Group>

            <Form.Group as={Col} className="mb-3" controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter email"
                defaultValue={state.data.email}
                required
              />
            </Form.Group>

            <Form.Group as={Col} className="mb-3" controlId="company_name">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                name="company_name"
                placeholder="Enter company name"
                defaultValue={state.data.company_name}
                required
              />
            </Form.Group>

            <Form.Group as={Col} className="mb-3" controlId="billing_email">
              <Form.Label>Billing Email</Form.Label>
              <Form.Control
                type="email"
                name="billing_email"
                placeholder="Enter billing email"
                defaultValue={state.data.billing_email}
                required
              />
            </Form.Group>

            <Form.Group as={Col} className="mb-3" controlId="vat">
              <Form.Label>VAT</Form.Label>
              <Form.Control
                type="text"
                name="vat"
                placeholder="Enter vat"
                defaultValue={state.data.vat}
              />
            </Form.Group>

            <Row className="mt-4">
              <Col sm="12" md="6">
                <Form.Group as={Col} className="mb-3" controlId="is_customer">
                  <Form.Check
                    type="checkbox"
                    name="is_customer"
                    label="Is customer"
                    defaultChecked={state.data.is_customer}
                  />
                </Form.Group>
              </Col>

              <Col sm="12" md="6">
                <Form.Group as={Col} className="mb-3" controlId="is_selling_partner">
                  <Form.Check
                    type="checkbox"
                    name="is_selling_partner"
                    label="Is selling partner"
                    defaultChecked={state.data.is_selling_partner}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>

        <hr />

        <Row>
          <h4 className="mb-4">Address</h4>

          {['Street 1', 'Street 2', 'City', 'State', 'Zip Code', 'Country'].map((field) => (
            <Col sm="12" md="6" key={field}>
              <Form.Group as={Col} className="mb-3" controlId={field}>
                <Form.Label>{field}</Form.Label>
                <Form.Control
                  type="text"
                  name={field.toLowerCase().replace(' ', '_')}
                  placeholder={`Enter ${field}`}
                  defaultValue={state.data[field.toLowerCase().replace(' ', '_')]}
                />
              </Form.Group>
            </Col>
          ))}
        </Row>

        <Row>
          <Form.Group as={Col} className="mb-3" controlId="comments">
            <Form.Label>Comments</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="comments"
              placeholder="Enter comments"
              defaultValue={state.data.comments}
            />
          </Form.Group>
        </Row>

        <hr />

        <h4 className="mb-4">Technical</h4>
        <Row className="mb-3">
          <Col sm="12" md="6">
            <Form.Group as={Col} className="mb-3" controlId="repository">
              <Form.Label>Repository</Form.Label>
              <Form.Control
                type="text"
                name="repository"
                placeholder="Enter repository"
                defaultValue={state.data.repository}
              />
            </Form.Group>
          </Col>

          <Col sm="12" md="6">
            <Form.Group as={Col} className="mb-3" controlId="odoo_url">
              <Form.Label>Odoo URL</Form.Label>
              <Form.Control
                type="text"
                name="odoo_url"
                placeholder="Enter odoo url"
                defaultValue={state.data.odoo_url}
              />
            </Form.Group>
          </Col>
        </Row>

        {
          state.data.api_key && (
            <Row>
              <Col sm="12" md="12">
                <Form.Group as={Col} className="mb-3" controlId="api_key">
                  <Form.Label>API Key</Form.Label>
                  <Form.Control
                    type="text"
                    name="api_key"
                    placeholder="Enter api key"
                    defaultValue={state.data.api_key}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
          )
        }

        <Row>
          <Button variant="primary" type="submit">Save</Button>
        </Row>
      </Form>
    </Row>
  );
}

export default CreateOrEditCustomer;
