import React from 'react';
import { Container } from 'react-bootstrap';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import './App.css';
import Menu from './Common/Menu';
import ErrorFallback from './Common/ErrorFallback';
import NotFound from './Pages/404';
import Home from './Pages/Admin/Home';
import CreateOrEditAutopushRule from './Pages/Admin/Autopush/CreateOrEdit';
import CustomersList from './Pages/Admin/Customers/List';
import ProductsList from './Pages/Admin/Products/List';
import LinksList from './Pages/Admin/Autopush/List';
import CreateOrEditProduct from './Pages/Admin/Products/CreateOrEdit';
import CreateOrEditCustomer from './Pages/Admin/Customers/CreateOrEdit';
import PrivateRoute from './Common/PrivateRoute';
import { useAuth } from './utils';
import Login from './Pages/Auth/Login';
import SubscriptionConstructor from './Pages/SubscriptionConstructor';
import CreateOrEditSubscription from './Pages/Admin/Subscriptions/CreateOrEdit';
import SubscriptionsList from './Pages/Admin/Subscriptions/List';
import SupportTicketsList from './Pages/Admin/SupportTickets/List';
import Profile from './Pages/Admin/Profile';
import BranchesList from './Pages/Admin/Branch/List';
import CreateOrEditBranch from './Pages/Admin/Branch/CreateOrEdit';
import WebauthnLogin from './Pages/Auth/WebauthnLogin';

function App() {
  const [logged] = useAuth();

  return (
    <BrowserRouter basename="/">
      <Menu />
      <Container className="mt-4 mb-4">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Routes>
            <Route path="" element={<SubscriptionConstructor />} />
            <Route path="login" element={<Login />} />
            <Route path="login/webauthn" element={<WebauthnLogin />} />

            <Route path="admin" element={<PrivateRoute logged={logged} />}>
              <Route path="" element={<Home />} />

              {/* Customers */}
              <Route path="customers" element={<CustomersList />} />
              <Route path="customers/new" element={<CreateOrEditCustomer />} />
              <Route path="customers/:customerId" element={<CreateOrEditCustomer />} />

              {/* Subscriptions */}
              <Route path="subscriptions" element={<SubscriptionsList />} />
              <Route path="subscriptions/new" element={<CreateOrEditSubscription />} />
              <Route path="subscriptions/:subscriptionId" element={<CreateOrEditSubscription />} />

              {/* Products */}
              <Route path="products" element={<ProductsList />} />
              <Route path="products/new" element={<CreateOrEditProduct />} />
              <Route path="products/:productId" element={<CreateOrEditProduct />} />

              {/* Branches */}
              <Route path="branches" element={<BranchesList />} />
              <Route path="branches/new" element={<CreateOrEditBranch />} />
              <Route path="branches/:branchId" element={<CreateOrEditBranch />} />

              {/* Autopush links */}
              <Route path="autopush" element={<LinksList />} />
              <Route path="autopush/new" element={<CreateOrEditAutopushRule />} />
              <Route path="autopush/:ruleId" element={<CreateOrEditAutopushRule />} />

              {/* Support Tickets */}
              <Route path="support-tickets" element={<SupportTicketsList />} />

              {/* Profile */}
              <Route path="profile" element={<Profile />} />
            </Route>

            {/* Not Found */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </ErrorBoundary>
      </Container>
    </BrowserRouter>
  );
}

export default App;
