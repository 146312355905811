import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Button, Col, Form, Row, Spinner } from 'react-bootstrap';

import { reducer, sendRequest, serializeForm } from '../../../utils';
import ProductAndVersionSelector from '../Components/ProductAndVersionSelector';
import CustomerAndBranchSelector from '../Components/CustomerAndBranchSelector';

const { REACT_APP_PUBLIC_KEY } = process.env;

function CreateOrEditAutopushRule() {
  const { ruleId } = useParams();
  const navigate = useNavigate();

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isLoading: false, isError: false,
    },
  );

  const onFormSubmit = (e) => {
    e.preventDefault();
    dispatch({ type: 'FETCH_INIT' });

    const opts = serializeForm(e.target);
    const route = ruleId ? `autopush-rules/${ruleId}` : 'autopush-rules';
    const method = ruleId ? 'PUT' : 'POST';

    sendRequest({
      route,
      method,
      body: opts,
      onSuccess: () => { navigate('/admin/autopush'); },
      onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
    });
  };

  React.useEffect(() => {
    if (!ruleId) return;

    dispatch({ type: 'FETCH_INIT' });

    sendRequest({
      route: `autopush-rules/${ruleId}`,
      method: 'GET',
      onSuccess: (rule) => {
        dispatch({ type: 'FETCH_SUCCESS', payload: rule });
      },
      onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
    });
  }, [ruleId]);

  return (
    <Row className="mb-4">
      <h3 className="d-flex justify-content-between align-items-center mb-4">
        Configure autopush to customer repository
        {state.isLoading && <Spinner animation="border" />}
      </h3>

      {state.isError && (
        <Alert variant="danger">
          {state.error}
        </Alert>
      )}

      {state.message && (
        <Alert variant="success">
          {state.message}
        </Alert>
      )}

      <Form onSubmit={onFormSubmit}>
        <Row>
          <h5>General Parameters</h5>
          <Col md="12">
            <CustomerAndBranchSelector
              defaultCustomerId={state.data.customer_id}
              defaultBranchId={state.data.branch_id}
              disabled={!!ruleId}
              required
            />
          </Col>

          <hr />

          <Col md="12">
            <ProductAndVersionSelector
              defaultProductId={state.data.product_id}
              defaultVersion={state.data.version}
              disabled={!!ruleId}
              required
            />
          </Col>
        </Row>

        <Row>
          <Button type="submit">Save autopush rule</Button>
        </Row>

        <hr />

        <Row>
          <Col md="12">
            <Alert variant="warning">
              <Alert.Heading className="mb-3">Do not forget to add public key to the customer&apos;s repository!</Alert.Heading>
              <pre className="public-key">
                {REACT_APP_PUBLIC_KEY}
              </pre>
            </Alert>
          </Col>

        </Row>
      </Form>
    </Row>
  );
}

export default CreateOrEditAutopushRule;
