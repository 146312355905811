import React from 'react';
import { Alert, Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { reducer, sendRequest, serializeForm } from '../../../utils';
import CustomerSelector from '../Components/CustomerSelector';
import ProductsSelector from '../Components/ProductsSelector';

function CreateOrEditSubscription() {
  const { subscriptionId } = useParams();
  const navigation = useNavigate();

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: {}, message: '', error: '', isLoading: false, isError: false,
    },
  );

  const handleFormSubmit = (e) => {
    e.preventDefault();

    dispatch({ type: 'FETCH_INIT' });

    // Get form content
    const opts = serializeForm(e.target, ['product_ids']);

    const route = subscriptionId ? `subscriptions/${subscriptionId}` : 'subscriptions';
    const method = subscriptionId ? 'PUT' : 'POST';

    sendRequest({
      route,
      method,
      body: opts,
      onSuccess: (customer) => {
        dispatch({ type: 'FETCH_SUCCESS', payload: customer });
        navigation(`/admin/subscriptions/${customer.id}`);
      },
      onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
    });
  };

  React.useEffect(() => {
    if (subscriptionId) {
      dispatch({ type: 'FETCH_INIT' });

      sendRequest({
        route: `subscriptions/${subscriptionId}`,
        method: 'GET',
        onSuccess: (subscription) => {
          dispatch({ type: 'FETCH_SUCCESS', payload: subscription });
        },
        onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
      });
    }
  }, [subscriptionId]);

  return (
    <Row className="mb-4">
      <h3 className="d-flex justify-content-between align-items-center mb-4">
        {subscriptionId ? 'Edit subscription' : 'Add new subscription'}
        {state.isLoading && <Spinner animation="border" />}
      </h3>

      {state.isError && (
        <Alert variant="danger">
          {state.error}
        </Alert>
      )}

      {state.message && (
        <Alert variant="success">
          {state.message}
        </Alert>
      )}

      <Form onSubmit={handleFormSubmit}>

        <h4 className="mb-4">General</h4>

        <Row className="mb-3">
          <Col>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                defaultValue={state.data.name}
                placeholder="Enter name"
                required
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="price">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                step="0.01"
                name="price"
                defaultValue={state.data.price}
                placeholder="Enter price"
                required
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="commision">
              <Form.Label>Commision</Form.Label>
              <Form.Control
                type="number"
                step="0.01"
                name="commision"
                defaultValue={state.data.commision}
                placeholder="Enter commision"
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group controlId="comments">
              <Form.Label>Comments</Form.Label>
              <Form.Control
                as="textarea"
                name="comments"
                defaultValue={state.data.comments}
                placeholder="Enter comments"
              />
            </Form.Group>
          </Col>
        </Row>

        <h4 className="mt-4 mb-4">Details</h4>

        <Row className="mb-3">
          <Col>
            <CustomerSelector
              label="Customer"
              name="customer_id"
              defaultValue={state.data.customer_id}
              // onChange={() =>}
              required
            />
            <CustomerSelector
              label="Selling Partner"
              name="selling_partner_id"
              defaultValue={state.data.selling_partner_id}
              // onChange={setSellingPartnerId}
              onlySellingPartners
            />
          </Col>

          <Col>
            <ProductsSelector
              defaultValue={state.data.product_ids}
              name="product_ids"
              label="Products"
              // onChange={}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} className="mb-3" controlId="date_started">
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              type="date"
              name="date_started"
              placeholder="Enter start date"
              defaultValue={state.data.date_started}
              required
            />
          </Form.Group>

          <Form.Group as={Col} className="mb-3" controlId="date_ended">
            <Form.Label>End Date</Form.Label>
            <Form.Control
              type="date"
              name="date_ended"
              placeholder="Enter end date"
              defaultValue={state.data.date_ended}
              required
            />
          </Form.Group>
        </Row>

        <h4 className="mt-3 mb-4">Technical</h4>

        <Row className="mb-3">
          <Col>
            <Form.Group controlId="active">
              <Form.Check
                type="checkbox"
                name="is_active"
                label="Active"
                defaultChecked={state.data.is_active}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group controlId="stripe_id">
              <Form.Label>Stripe ID</Form.Label>
              <Form.Control
                type="text"
                name="stripe_id"
                defaultValue={state.data.stripe_id}
                placeholder="Enter Stripe ID"
              />
            </Form.Group>
          </Col>

          <Col sm="12" md="3" className="d-flex align-items-end">
            <Button
              href={`https://dashboard.stripe.com/subscriptions/${state.data.stripe_id}`}
              variant="outline-primary"
              target="_blank"
              disabled={!state.data.stripe_id}
              className="w-100"
            >
              Open
            </Button>
          </Col>
        </Row>

        <Row>
          <Button variant="primary" type="submit">Save</Button>
        </Row>
      </Form>
    </Row>
  );
}

export default CreateOrEditSubscription;
