import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

function ImageUploader({ defaultImage, onImageUpload }) {
  const [showHover, setShowHover] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
      onImageUpload(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleImageDrop = (e) => {
    e.preventDefault();

    const file = e.dataTransfer.files[0];

    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
      setShowHover(false);

      onImageUpload(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setShowHover(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setShowHover(false);
  };

  React.useEffect(() => {
    if (defaultImage) {
      setImagePreview(defaultImage);
    }
  }, [defaultImage]);

  return (
    <button
      className={`image-uploader ${imagePreview ? '' : 'no-image'}`}
      onClick={() => fileInputRef.current.click()}
      onDrop={handleImageDrop}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={(e) => e.preventDefault()}
      type="button"
    >
      {(showHover || !imagePreview) && 'Click or drag and drop an image here'}

      {imagePreview && (<img className="image-preview" src={imagePreview} alt="Preview" />)}

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleImageChange}
      />
    </button>
  );
}

ImageUploader.propTypes = {
  defaultImage: PropTypes.string,
  onImageUpload: PropTypes.func.isRequired,
};

ImageUploader.defaultProps = {
  defaultImage: null,
};

export default ImageUploader;
