import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form } from 'react-bootstrap';
import { reducer, sendRequest } from '../../../utils';

function ProductsSelector({ name, label, defaultValue, disabled, onChange }) {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isLoading: true, isError: false,
    },
  );

  const handleProductChange = (e) => {
    const selectedValues = [...e.target.selectedOptions].map(
      (option) => option.value,
    ).filter((value) => value);
    onChange(selectedValues);
  };

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest({
      route: 'products',
      method: 'GET',
      onSuccess: (products) => {
        dispatch({ type: 'FETCH_SUCCESS', payload: products });
      },
      onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
    });
  }, [defaultValue]);

  return (
    state.isLoading
      ? <p>Loading...</p>
      : (
        <Form.Group as={Col} md="12" className="mb-3" controlId="products">
          <Form.Label>{label}</Form.Label>
          <Form.Select
            name={name}
            aria-label="Select Product"
            onChange={handleProductChange}
            defaultValue={defaultValue}
            disabled={disabled}
            multiple
          >
            {
                state.data.map(
                  (product) => <option value={product.id} key={product.id}>{product.name}</option>,
                )
              }
          </Form.Select>
        </Form.Group>
      )
  );
}

ProductsSelector.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  defaultValue: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  disabled: PropTypes.bool,
};

ProductsSelector.defaultProps = {
  onChange: () => {},
  defaultValue: null,
  disabled: false,
};

export default ProductsSelector;
