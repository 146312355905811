import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Alert, Button, Card, Col, Container, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import { sendRequest } from '../utils';

const PRODUCTS = {
  'eCommerce & Connectors': [
    'Shopify',
    'PrestaShop',
    'WooCommerce',
    'Magento 2',
    'QuickBooks Online connector',
  ],
  Printing: [
    'Direct Print PRO',
    'Direct Print for POS',
    'ZPL Label Designer',
  ],
};

const SUBSCRIPTIONS = [
  {
    id: 'connectors',
    name: 'Single Connector (+ Support & Maintenance)',
    products: ['Shopify', 'PrestaShop', 'WooCommerce', 'Magento 2'],
    price_raw: 499,
    price: (
      <>
        € 499 / year
      </>
    ),
    price_note: `billed annually (this is only € ${Math.round(499 / 12)} per month)`,
    payment_link: 'https://buy.stripe.com/6oE3dgcH1g1F2qc28e',
    description: (
      <ol>
        <li>
          Any single available connector at your choice (
          <a href="https://apps.odoo.com/apps/modules/16.0/integration_shopify/" target="_blank" rel="noreferrer">Shopify</a>
          ,
          {' '}
          <a href="https://apps.odoo.com/apps/modules/16.0/integration_prestashop/" target="_blank" rel="noreferrer">PrestaShop</a>
          ,
          {' '}
          <a href="https://apps.odoo.com/apps/modules/16.0/integration_woocommerce/" target="_blank" rel="noreferrer">Woocommerce</a>
          {' '}
          <a href="https://apps.odoo.com/apps/modules/16.0/integration_magento2/" target="_blank" rel="noreferrer">Magento 2</a>
          )
        </li>
        <li>Access and updates for three latest Odoo versions (e.g. 15, 16, 17 in 2024)</li>
        <li>Initial installation of the module on your server / odoo.sh by request</li>
        <li>
          Initial configuration of connector in not included but can be purchased separately:
          <br />
          <a href="https://buy.stripe.com/7sI03h6rteeHdFu6oo">Initial Configuration</a>
        </li>
        <li>
          Priority support while subscription is active. According to the Odoo App store policy
          we have 15 days to fix the issue. But with subscription it will be much faster
          (either same day or within 3 days depending on complexity of the issue)
        </li>
        <li>
          Automatically push updates to your repository in a separate branch so you do not need to download
          and worry about downloading new updates yourself. We automatically push changes to your git!
        </li>
        <li>
          Possibility to influence roadmap (backlog items suggested by subscribed customers are put on top of the backlog)
        </li>
      </ol>
    ),
    odoo_link: 'https://apps.odoo.com/apps/modules/browse?author=VentorTech',
    odoo_price: '499 € / connector',
    odoo_price_note: 'one time payment',
    odoo_description: (
      <ol>
        <li>
          Any single available connector at your choice (
          <a href="https://apps.odoo.com/apps/modules/16.0/integration_shopify/" target="_blank" rel="noreferrer">Shopify</a>
          ,
          {' '}
          <a href="https://apps.odoo.com/apps/modules/16.0/integration_prestashop/" target="_blank" rel="noreferrer">PrestaShop</a>
          ,
          {' '}
          <a href="https://apps.odoo.com/apps/modules/16.0/integration_woocommerce/" target="_blank" rel="noreferrer">Woocommerce</a>
          ,
          {' '}
          <a href="https://apps.odoo.com/apps/modules/16.0/integration_magento2/" target="_blank" rel="noreferrer">Magento 2</a>
          )
        </li>
        <li>
          No installation and configuration included. You need to do it yourself.
        </li>
        <li>Access and updates for single purchased Odoo version (e.g. 15)</li>
        <li>
          Standard support. According to the Odoo App store policy we have 15 days to fix the issue.
        </li>
      </ol>
    ),
  },
  {
    id: 'multiple-connectors',
    name: 'Multiple Connectors (+ Support & Maintenance)',
    products: ['Shopify', 'PrestaShop', 'WooCommerce', 'Magento 2'],
    price_raw: 999,
    price: (
      <>
        € 999 / year
      </>
    ),
    price_note: `billed annually (this is only € ${Math.round(999 / 12)} per month)`,
    payment_link: 'https://buy.stripe.com/aEU9BE6iD8zd3ugeV9',
    description: (
      <ol>
        <li>
          Any single available connector at your choice (
          <a href="https://apps.odoo.com/apps/modules/16.0/integration_shopify/" target="_blank" rel="noreferrer">Shopify</a>
          ,
          {' '}
          <a href="https://apps.odoo.com/apps/modules/16.0/integration_prestashop/" target="_blank" rel="noreferrer">PrestaShop</a>
          ,
          {' '}
          <a href="https://apps.odoo.com/apps/modules/16.0/integration_woocommerce/" target="_blank" rel="noreferrer">Woocommerce</a>
          {' '}
          <a href="https://apps.odoo.com/apps/modules/16.0/integration_magento2/" target="_blank" rel="noreferrer">Magento 2</a>
          )
        </li>
        <li>Access and updates for three latest Odoo versions (e.g. 15, 16, 17 in 2024)</li>
        <li>Initial installation of the module on your server / odoo.sh by request</li>
        <li>
          Initial configuration of connector in not included but can be purchased separately:
          <br />
          <a href="https://buy.stripe.com/7sI03h6rteeHdFu6oo">Initial Configuration</a>
        </li>
        <li>
          Priority support while subscription is active. According to the Odoo App store policy
          we have 15 days to fix the issue. But with subscription it will be much faster
          (either same day or within 3 days depending on complexity of the issue)
        </li>
        <li>
          Automatically push updates to your repository in a separate branch so you do not need to download
          and worry about downloading new updates yourself. We automatically push changes to your git!
        </li>
        <li>
          Possibility to influence roadmap (backlog items suggested by subscribed customers are put on top of the backlog)
        </li>
      </ol>
    ),
    odoo_link: 'https://apps.odoo.com/apps/modules/browse?author=VentorTech',
    odoo_price: '499 € / connector x number of connectors',
    odoo_price_note: 'one time payment',
    odoo_description: (
      <ol>
        <li>
          Any multiple available connector at your choice (
          <a href="https://apps.odoo.com/apps/modules/16.0/integration_shopify/" target="_blank" rel="noreferrer">Shopify</a>
          ,
          {' '}
          <a href="https://apps.odoo.com/apps/modules/16.0/integration_prestashop/" target="_blank" rel="noreferrer">PrestaShop</a>
          ,
          {' '}
          <a href="https://apps.odoo.com/apps/modules/16.0/integration_woocommerce/" target="_blank" rel="noreferrer">Woocommerce</a>
          ,
          {' '}
          <a href="https://apps.odoo.com/apps/modules/16.0/integration_magento2/" target="_blank" rel="noreferrer">Magento 2</a>
          )
        </li>
        <li>
          No installation and configuration included. You need to do it yourself.
        </li>
        <li>Access and updates for single purchased Odoo version (e.g. 15)</li>
        <li>
          Standard support. According to the Odoo App store policy we have 15 days to fix the issue.
        </li>
      </ol>
    ),
  },
  {
    id: 'printing',
    name: 'Basic Printing Package (+ Support & Maintenance)',
    products: ['Direct Print PRO', 'Direct Print for POS', 'ZPL Label Designer'],
    price_raw: 599,
    price: (
      <>
        € 599 / year
      </>
    ),
    price_note: `billed annually (this is only € ${Math.round(599 / 12)} per month)`,
    payment_link: 'https://buy.stripe.com/fZedRUayTg1Fd4Q9AK',
    description: (
      <ol>
        <li>
          <a href="https://apps.odoo.com/apps/modules/16.0/printnode_base/" target="_blank" rel="noreferrer">Direct Print PRO</a>
          {' '}
          (by request if needed any print-related modules like
          {' '}
          <a href="https://apps.odoo.com/apps/modules/16.0/zpl_label_designer/" target="_blank" rel="noreferrer">ZPL Label Designer</a>
          {' '}
          and
          {' '}
          <a href="https://apps.odoo.com/apps/modules/16.0/printnode_pos/" target="_blank" rel="noreferrer">Direct Print for POS</a>
          )
        </li>
        <li>Print Server subscription is included for the period of subscription (no need to pay separately)</li>
        <li>Access and updates for three latest Odoo versions of modules (e.g. 15, 16, 17 in 2024)</li>
        <li>Initial installation of the module on your server / odoo.sh by request</li>
        <li>
          Help in the initial configuration. We help to configure the module so you will print
          the first Stock Picking document and Shipping Label (if you use Odoo standard shipping connectors),
          so you make sure the module works for you. After this you configure the rest yourself based on our documentation.
        </li>
        <li>
          Priority support while the subscription is active. According to the Odoo App store policy,
          we have 15 days to fix the issue. But with a subscription, it will be much faster
          (either the same day or within 3 days depending on the complexity of the issue)
        </li>
        <li>
          Automatically push updates to your repository in a separate branch,
          so you do not need to worry about downloading new updates yourself.
          We automatically push changes to your git!
        </li>
        <li>Possibility to influence roadmap (backlog items suggested by subscribed customers are put on top of the backlog)</li>
      </ol>
    ),
    odoo_link: 'https://apps.odoo.com/apps/modules/browse?author=VentorTech',
    odoo_price: '586 €',
    odoo_price_note: (
      <>
        Modules (one time payment) + Printing Subscription (23.99 € / month)
      </>
    ),
    odoo_description: (
      <ol>
        <li>
          You can purchase apps in Odoo Apps (
          <a href="https://apps.odoo.com/apps/modules/16.0/printnode_base/" target="_blank" rel="noreferrer">Direct Print PRO</a>
          ,
          {' '}
          <a href="https://apps.odoo.com/apps/modules/16.0/zpl_label_designer/" target="_blank" rel="noreferrer">ZPL Label Designer</a>
          ,
          {' '}
          <a href="https://apps.odoo.com/apps/modules/16.0/printnode_pos/" target="_blank" rel="noreferrer">Direct Print for POS</a>
          )
        </li>
        <li>
          No installation and configuration included. You need to do it yourself.
        </li>
        <li>Access and updates for single purchased Odoo version (e.g. 15)</li>
        <li>
          Standard support. According to the Odoo App store policy we have 15 days to fix the issue.
        </li>
      </ol>
    ),
  },
  {
    id: 'printing-ecommerce',
    name: 'Printing + eCommerce (+ Support & Maintenance)',
    products: [
      'Direct Print PRO', 'Direct Print for POS', 'ZPL Label Designer',
      'Shopify', 'PrestaShop', 'WooCommerce', 'Magento 2',
    ],
    price_raw: 899,
    price: (
      <>
        € 899 / year
      </>
    ),
    price_note: `billed annually (this is only € ${Math.round(899 / 12)} per month)`,
    payment_link: 'https://buy.stripe.com/fZe3dgbCX5n1fcY6ot',
    description: (
      <ol>
        <li className="mb-2">
          Any single available connector at your choice (
          <a href="https://apps.odoo.com/apps/modules/16.0/integration_shopify/" target="_blank" rel="noreferrer">Shopify</a>
          ,
          {' '}
          <a href="https://apps.odoo.com/apps/modules/16.0/integration_prestashop/" target="_blank" rel="noreferrer">PrestaShop</a>
          ,
          {' '}
          <a href="https://apps.odoo.com/apps/modules/16.0/integration_woocommerce/" target="_blank" rel="noreferrer">Woocommerce</a>
          ,
          {' '}
          <a href="https://apps.odoo.com/apps/modules/16.0/integration_magento2/" target="_blank" rel="noreferrer">Magento 2</a>
          )
        </li>
        <li className="mb-2">
          <a href="https://apps.odoo.com/apps/modules/16.0/printnode_base/" target="_blank" rel="noreferrer">Direct Print PRO</a>
          {' '}
          (by request if needed any print-related modules like
          {' '}
          <a href="https://apps.odoo.com/apps/modules/16.0/zpl_label_designer/" target="_blank" rel="noreferrer">ZPL Label Designer</a>
          {' '}
          and
          {' '}
          <a href="https://apps.odoo.com/apps/modules/16.0/printnode_pos/" target="_blank" rel="noreferrer">Direct Print for POS</a>
          )
        </li>
        <li className="mb-2">Print Server subscription is included for the period of subscription (no need to pay separately)</li>
        <li className="mb-2">Access and updates for three latest Odoo versions (e.g. 15, 16, 17 in 2024)</li>
        <li className="mb-2">Initial installation of the module on your server / odoo.sh by request</li>
        <li className="mb-2">
          Initial configuration of connector in not included but can be purchased separately:
          <br />
          <a href="https://buy.stripe.com/7sI03h6rteeHdFu6oo">Initial Configuration</a>
        </li>
        <li className="mb-2">
          Help in the initial configuration. We help to configure the module so you will print the
          first Stock Picking document and Shipping Label (if you use Odoo standard shipping connectors),
          so you make sure the module works for you. After this you configure the rest yourself based on our documentation.
        </li>
        <li className="mb-2">
          Priority support while subscription is active. According to the Odoo App store policy
          we have 15 days to fix the issue. But with subscription it will be much faster
          (either same day or within 3 days depending on complexity of the issue)
        </li>
        <li className="mb-2">
          Automatically push updates to your repository in a separate branch so you do not need
          to worry about downloading new updates yourself. We automatically push changes to your git!
        </li>
        <li className="mb-2">
          Possibility to influence roadmap (backlog items suggested by subscribed customers are put on top of the backlog)
        </li>
      </ol>
    ),
    odoo_link: 'https://apps.odoo.com/apps/modules/browse?author=VentorTech',
    odoo_price: '1085 €',
    odoo_price_note: (
      <>
        Modules (one time payment) + Printing Subscription (23.99 € / month)
      </>
    ),
    odoo_description: (
      <ol>
        <li className="mb-2">
          Any single available connector at your choice (
          <a href="https://apps.odoo.com/apps/modules/16.0/integration_shopify/" target="_blank" rel="noreferrer">Shopify</a>
          ,
          {' '}
          <a href="https://apps.odoo.com/apps/modules/16.0/integration_prestashop/" target="_blank" rel="noreferrer">PrestaShop</a>
          ,
          {' '}
          <a href="https://apps.odoo.com/apps/modules/16.0/integration_woocommerce/" target="_blank" rel="noreferrer">Woocommerce</a>
          ,
          {' '}
          <a href="https://apps.odoo.com/apps/modules/16.0/integration_magento2/" target="_blank" rel="noreferrer">Magento 2</a>
          )
        </li>
        <li className="mb-2">
          You can purchase apps in Odoo Apps (
          <a href="https://apps.odoo.com/apps/modules/16.0/printnode_base/" target="_blank" rel="noreferrer">Direct Print PRO</a>
          ,
          {' '}
          <a href="https://apps.odoo.com/apps/modules/16.0/zpl_label_designer/" target="_blank" rel="noreferrer">ZPL Label Designer</a>
          ,
          {' '}
          <a href="https://apps.odoo.com/apps/modules/16.0/printnode_pos/" target="_blank" rel="noreferrer">Direct Print for POS</a>
          )
        </li>
        <li className="mb-2">
          No installation and configuration included. You need to do it yourself.
        </li>
        <li className="mb-2">Access and updates for single purchased Odoo version (e.g. 15)</li>
        <li className="mb-2">
          Standard support. According to the Odoo App store policy we have 15 days to fix the issue.
        </li>
      </ol>
    ),
  },
  {
    id: 'quickbooks',
    name: 'QuickBooks Online connector (+ Support & Maintenance)',
    products: ['QuickBooks Online connector'],
    price_raw: 199,
    price: (
      <>
        € 199 / year
      </>
    ),
    price_note: `billed annually (this is only € ${Math.round(199 / 12)} per month)`,
    payment_link: 'https://buy.stripe.com/14k0146iD5n17Kw7sA',
    description: (
      <ol>
        <li className="mb-2">
          <a href="https://apps.odoo.com/apps/modules/16.0/quickbooks_sync_online/" target="_blank" rel="noreferrer">
            QuickBooks Online connector PRO
          </a>
        </li>
        <li className="mb-2">Access and updates for three latest Odoo versions (e.g. 15, 16, 17 in 2024)</li>
        <li className="mb-2">
          Initial installation of the module on your server / odoo.sh by request
          (Configuration of the module is still done by you as we have very detailed video explaining the process)
        </li>
        <li className="mb-2">
          Help in the initial configuration. We help to configure the module so you will print the
          first Stock Picking document and Shipping Label (if you use Odoo standard shipping connectors),
          so you make sure the module works for you. After this you configure the rest yourself based on our documentation.
        </li>
        <li className="mb-2">
          Priority support while subscription is active. According to the Odoo App store policy
          we have 15 days to fix the issue. But with subscription it will be much faster
          (either same day or within 3 days depending on complexity of the issue)
        </li>
        <li className="mb-2">
          Automatically push updates to your repository in a separate branch so you do not need
          to worry about downloading new updates yourself. We automatically push changes to your git!
        </li>
        <li className="mb-2">Possibility to influence roadmap (backlog items suggested by subscribed customers are put on top of the backlog)</li>
      </ol>
    ),
    odoo_link: 'https://apps.odoo.com/apps/modules/16.0/quickbooks_sync_online/',
    odoo_price: '199 €',
    odoo_price_note: '(QuickBooks Online connector PRO, one time payment)',
    odoo_description: (
      <ol>
        <li className="mb-2">
          <a href="https://apps.odoo.com/apps/modules/16.0/quickbooks_sync_online/" target="_blank" rel="noreferrer">
            QuickBooks Online connector PRO
          </a>
        </li>
        <li className="mb-2">
          No installation and configuration included. You need to do it yourself.
        </li>
        <li className="mb-2">Access and updates for single purchased Odoo version (e.g. 15)</li>
        <li className="mb-2">
          Standard support. According to the Odoo App store policy we have 15 days to fix the issue.
        </li>
      </ol>
    ),
  },
];

function WhyVentorTech() {
  return (
    <div className="mt-5">
      <h3>More information</h3>

      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Issues that we see with modules in Odoo world</Accordion.Header>
          <Accordion.Body>
            <ol>
              <li className="mb-2">
                Upgrading between major Odoo versions requires separate purchases on Odoo Apps.
                You can be on Odoo 14, purchase modules for Odoo 15 as decided to start the migration
                and because of a long migration, then require to purchase Odoo 16 already!
              </li>
              <li className="mb-2">
                Upgrading Odoo modules is a hassle: requires downloading from Odoo Apps manually,
                and there are no notifications about new releases
              </li>
              <li className="mb-2">
                If you purchase modules from many Vendors they may not be compatible.
                So preferred to purchase from Vendors known to be good at their quality service
                and code.
              </li>
              <li className="mb-2">
                In many cases even good documentation does not allow you to install and configure
                modules quickly. And it sometimes takes ages to do this.
              </li>
              <li className="mb-2">
                Support for most of the modules is very poor. Most of the popular module publishers
                release hundreds of modules they are not able to support and maintain well.
                Resulting in poor customer experience.
              </li>
              <li className="mb-2">
                Consequence of the previous point, because of too broad focus on huge amounts of
                modules - individual modules do not get improvements and new features. So, in 1 year
                it is becoming unmaintained and outdated.
              </li>
            </ol>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>What are we (VentorTech) doing differently?</Accordion.Header>
          <Accordion.Body>
            <ol>
              <li className="mb-2">
                We develop a limited number of highly specialized solutions. So we have very strong expertise
                and a high focus on them.
              </li>
              <li className="mb-2">
                We release monthly/bimonthly updates for all our products allowing us to get the best
                of the possible functionality (check out release notes for every app on module Documentation tab).
              </li>
              <li className="mb-2">
                Our strongest point is our support (proven by ratings and comments on Odoo store).
                We are always ready to help you with any questions you may have.
              </li>
              <li className="mb-2">
                We can do this because there is a highly-skilled and dedicated team behind every product.
              </li>
            </ol>

            <p>
              Below proposition is based on customer and partner feedback and allows us to make sure
              that Customers will receive additional benefits, while the VentorTech team can further
              continue maintaining products and provide high-quality support as well as develop products further.
            </p>

            <p>
              We provide you with a Custom Ecosystem of products for yearly subscription.
              A few examples are below, but they can be adjusted to your needs depending on modules
              you need.
            </p>

          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header><strong>Are you Odoo partner?</strong></Accordion.Header>
          <Accordion.Body>
            <p>
              If you are an Odoo Partner and recommend your modules to your customers,
              you are eligible to receive 20% commission from all purchases made by customers
              in accordance to your recommendations. Please, inform us through
              {' '}
              <a href="mailto:support@ventor.tech">support@ventor.tech</a>
              {' '}
              if some customer has purchased one of the packages based on your recommendations.
            </p>
            <p>
              Would you like to use all our modules from Odoo App store for your customers?
              Please, contact us through
              {' '}
              <a href="mailto:support@ventor.tech">support@ventor.tech</a>
              {' '}
              for special conditions.
            </p>
            <p>
              In case your customer already purchased a module from the app store within the last 3 months,
              please, contact us to get a 50% discount for the first year on the maintenance contract.
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

function ContactUsModal({ show, onHide }) {
  const formRef = React.useRef(null);
  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleHide = () => {
    setError(null);
    onHide();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    formRef.current.classList.add('was-validated');

    if (!formRef.current.checkValidity()) {
      return;
    }

    setIsLoading(true);

    const data = {
      name: formRef.current.elements.formName.value,
      email: formRef.current.elements.formEmail.value,
      phone: formRef.current.elements.formPhone.value,
      question: formRef.current.elements.formQuestion.value,
    };

    sendRequest({
      route: 'support-tickets',
      method: 'POST',
      body: data,
      onSuccess: () => {
        setIsLoading(false);
        onHide();
      },
      onError: (error) => {
        setError(error || (
        <>
          Something went wrong. Please contact us directly:
          {' '}
          <a href="mailto:support@ventor.tech">support@ventor.tech</a>
        </>
        ));
        setIsLoading(false);
      },
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Contact Us
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && (
          <Alert variant="danger">
            {error}
          </Alert>
        )}

        <Form className="needs-validation" ref={formRef} noValidate>
          <Row className="mb-3">

            <Col>
              <Form.Group controlId="formName">
                <Form.Label><strong>Name</strong></Form.Label>
                <Form.Control type="text" placeholder="Enter name" required />
                <div className="valid-feedback">
                  Looks good!
                </div>
                <div className="invalid-feedback">
                  Please enter a name.
                </div>
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId="formEmail">
                <Form.Label><strong>Email address</strong></Form.Label>
                <Form.Control type="email" placeholder="Enter email" required />
                <div className="valid-feedback">
                  Looks good!
                </div>
                <div className="invalid-feedback">
                  Please enter a valid email address.
                </div>
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId="formPhone">
                <Form.Label>Phone (Optional)</Form.Label>
                <Form.Control type="phone" placeholder="Enter phone" />
              </Form.Group>
              <div className="valid-feedback">
                Looks good!
              </div>
            </Col>

            <Form.Text className="text-muted">
              We&apos;ll never share your contact details with anyone else.
            </Form.Text>
          </Row>

          <Row>
            <Form.Group controlId="formQuestion">
              <Form.Label><strong>Question</strong></Form.Label>
              <Form.Control as="textarea" rows={3} required />
              <div className="valid-feedback">
                Looks good!
              </div>
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" type="submit" onClick={handleSubmit} disabled={isLoading}>
          {isLoading && <Spinner variant="light" size="sm" animation="border" className="me-4" />}
          Submit
        </Button>
        <Button variant="outline-secondary" onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

ContactUsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

function SubscriptionConstructor() {
  const [searchParams, setSearchParams] = useSearchParams();
  const subscriptionId = searchParams.get('subscriptionId');
  const success = searchParams.get('success') === 'true' || false;

  const [error, setError] = React.useState(null);
  const [products, setProducts] = React.useState([]);
  // eslint-disable-next-line no-nested-ternary
  const [step, setStep] = React.useState(subscriptionId ? 1 : (success ? 2 : 0));
  const [subscription, setSubscription] = React.useState(subscriptionId ? SUBSCRIPTIONS.find((s) => s.id === subscriptionId) : null);
  const [support, setSupport] = React.useState(searchParams.get('support') === 'true' || false);

  const [showContactUsModal, setShowContactUsModal] = React.useState(false);

  const handleProductsChange = (e) => {
    setError(null);

    if (e.target.checked) {
      setProducts([...products, e.target.id]);
    } else {
      setProducts(products.filter((p) => p !== e.target.id));
    }
  };

  const handleProductSelectionConfirm = (e) => {
    e.preventDefault();

    // If no products selected, show error
    if (products.length === 0) {
      setError('Please select at least one product.');
      return;
    }

    setStep(1);

    if (products.length === 1 && products[0] === 'QuickBooks Online connector') {
      // If selected only QuickBooks Online connector, select "quickbooks" subscription
      setSubscription(SUBSCRIPTIONS.find((s) => s.id === 'quickbooks'));
    } else if (products.length === 1 && PRODUCTS['eCommerce & Connectors'].includes(products[0])) {
      // If selected single connector only, select "connector" subscription
      setSubscription(SUBSCRIPTIONS.find((s) => s.id === 'connectors'));
    } else {
      // If multiple connectors selected and only connectors,
      // select "multiple-connectors" subscription
      if (products.every((p) => PRODUCTS['eCommerce & Connectors'].includes(p))) {
        setSubscription(SUBSCRIPTIONS.find((s) => s.id === 'multiple-connectors'));
        return;
      }

      // If all products are related to printing, select "printing" subscription
      if (products.every((p) => PRODUCTS.Printing.includes(p))) {
        setSubscription(SUBSCRIPTIONS.find((s) => s.id === 'printing'));
        return;
      }

      // If selected multiple products including only single connector and some products from printing,
      // select "printing-ecommerce" subscription
      if (
        products.filter((p) => PRODUCTS['eCommerce & Connectors'].includes(p)).length === 1
        && products.some((p) => PRODUCTS.Printing.includes(p))
      ) {
        setSubscription(SUBSCRIPTIONS.find((s) => s.id === 'printing-ecommerce'));
      } else {
        setSubscription(null);
      }
    }
  };

  const handleSubscribeClick = (url) => {
    setStep(2);

    // Open Stripe URL in new tab
    window.open(url, '_blank');
  };

  const handleGoBackButtonClick = (e) => {
    e.preventDefault();

    setStep(0);
    setSubscription(null);
    setSearchParams({});
  };

  const handleContactUsButtonClick = (e) => {
    e.preventDefault();

    setShowContactUsModal(true);
  };

  React.useEffect(() => {
    if (!subscription) {
      if (success) {
        setStep(2);
        setSearchParams({ success });
      } else {
        setStep(0);
        setSearchParams({});
      }
      return;
    }

    setSearchParams({ subscriptionId: subscription.id });
  }, [subscription, support]);

  return (
    <Container>
      <h2 className="mb-4">VentorTech Ecosystem Builder</h2>

      { step === 0 && (
        <>
          <h3 className="mb-4">Step 1. Select the products you will need:</h3>
          <p>
            All our apps are a part of the VentorTech ecosystem. Each of our products is fully compatible with all products designed by VentorTech:
            Ventor, Odoo Shopify connector, Odoo PrestaShop connector, Odoo Magento connector, Odoo QuickBooks Online connector,
            Odoo MyParcel connector, Odoo WooCommerce connector, ZPL Label Designer, and others.
          </p>

          <Form className="mt-4 mb-4">
            <Form.Group className="mb-4" controlId="formProducts">
              {/* Render each key values of PRODUCTS as column */}
              <Row xs={1} md={2} className="g-4">
                {Object.keys(PRODUCTS).map((key) => (
                  <Col key={key}>
                    <Form.Label as="h4" className="mb-3">{key}</Form.Label>
                    {PRODUCTS[key].map((product) => (
                      <Form.Check
                        key={product}
                        type="checkbox"
                        id={product}
                        label={product}
                        defaultChecked={products.includes(product)}
                        onChange={handleProductsChange}
                      />
                    ))}
                  </Col>
                ))}
              </Row>

              <Row xs={1} md={2} className="g-4 d-none">
                <Col>
                  <Form.Label as="h4" className="mb-3">Other</Form.Label>
                  <strong>
                    <Form.Check
                      type="checkbox"
                      id="Premium Support"
                      label="Premium Support"
                      defaultChecked={support}
                      onChange={(e) => { setSupport(e.target.checked); }}
                    />
                  </strong>
                </Col>
              </Row>

              <Form.Text className="text-muted d-block mt-4">
                You may select as many products as you need.
              </Form.Text>
            </Form.Group>

            {error && (
              <Alert variant="danger" className="mb-4">
                {error}
              </Alert>
            )}

            <Button variant="primary" className="mb-3 mb-md-0" type="submit" onClick={handleProductSelectionConfirm}>
              I am ready! Find the best plan for me &rarr;
            </Button>

            <Button variant="outline-primary" className="ms-sm-0 ms-md-3 mb-0" onClick={handleContactUsButtonClick}>
              Have questions or need custom subscription? Contact Us
            </Button>
          </Form>

          <WhyVentorTech />
        </>
      )}

      { step === 1 && (
        <>
          <div className="mb-4">
            <Button
              onClick={handleGoBackButtonClick}
              variant="outline-secondary"
              className="me-3 mb-3 mb-sm-0"
            >
              &larr; Go back to products list
            </Button>
            <Button variant="outline-primary" onClick={handleContactUsButtonClick}>Have questions or need custom subscription? Contact Us</Button>
          </div>

          <h3 className="mb-4">Step 2. Review the best plan:</h3>

          { subscription && (
            <Row xs={1} md={2} className="g-4">
              <Col md="8" className="offset-md-2">
                <Card className="mb-4" border="primary">
                  <Card.Header as="h5" className="text-center bg-primary text-white">{subscription.name}</Card.Header>
                  <Card.Body>
                    <div className="text-center mt-4 mb-5">
                      <h2 className="card-title pricing-card-title">{subscription.price}</h2>
                      <small>{subscription.price_note}</small>
                    </div>

                    {subscription.description}
                    <Button variant="primary" onClick={() => handleSubscribeClick(subscription.payment_link)}>
                      Subscribe &rarr;
                    </Button>
                  </Card.Body>
                </Card>
              </Col>

              {/* <Col md="4">
                <Card className="mb-4">
                  <Card.Header as="h5" className="text-center">Odoo Apps</Card.Header>
                  <Card.Body>
                    <div className="text-center mt-4 mb-5">
                      <h2 className="card-title pricing-card-title">{subscription.odoo_price}</h2>
                      <small>{subscription.odoo_price_note}</small>
                    </div>
                    {subscription.odoo_description}
                    <a className="btn btn-outline-secondary" href={subscription.odoo_link} target="_blank" rel="noreferrer">
                      Purchase at Odoo Apps &rarr;
                    </a>
                  </Card.Body>
                </Card>
              </Col> */}
            </Row>
          )}

          { !subscription && (
            <Alert variant="warning">
              <Alert.Heading>Sorry, we can&apos;t find subscription plan for your needs</Alert.Heading>
              <hr />

              <h5>What to do next?</h5>
              <p>
                Please, contact us and we will help you to find best subscription plan for your needs.
              </p>
              <p>
                <Button variant="outline-primary" onClick={handleContactUsButtonClick}>Contact Us</Button>
              </p>
            </Alert>
          )}
        </>
      )}

      { step === 2 && (
        <>
          <div className="mb-4">
            <Button
              onClick={handleGoBackButtonClick}
              variant="outline-secondary"
              className="me-3 mb-3 mb-sm-0"
            >
              &larr; Go back to products list
            </Button>
            <Button variant="outline-primary" onClick={handleContactUsButtonClick}>Have questions or need custom subscription? Contact Us</Button>
          </div>

          <h3 className="mb-4">Step 3. What to do after subscription?</h3>

          <Alert variant="success">
            <Alert.Heading>Thank you for subscribing!</Alert.Heading>

            <hr />

            <p>
              After subscribing, you need to share access to your Github account with our special user &quot;ventor-ecosystem&quot;
              (if you are on Odoo.sh, please, add the same user to your Odoo.sh also). Video how to share access to odoo.sh
            </p>
            <p>
              If your git is not on github - please, add this user with this email to your git &quot;git-ecosystem@ventor.tech&quot;
            </p>

            After this send an email to
            {' '}
            <a href="mailto:support@ventor.tech">support@ventor.tech</a>
            {' '}
            with:
            <ul>
              <li>Link to your repo</li>
              <li>Odoo version</li>
              <li>Invoice number</li>
              <li>Name of the connector in case package with connector was purchased (e.g. Shopify)</li>
            </ul>
          </Alert>
        </>
      )}

      <ContactUsModal show={showContactUsModal} onHide={() => setShowContactUsModal(false)} />
    </Container>
  );
}

export default SubscriptionConstructor;
