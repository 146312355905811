import React from 'react';
import { Alert, Badge, Button, Row, Spinner, Table } from 'react-bootstrap';
import { BsCheckSquare, BsSquare } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';

import { reducer, sendRequest } from '../../../utils';

function SubscriptionsList() {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isLoading: true, isError: false,
    },
  );

  const handleDeleteSubscription = (subscriptionId) => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest({
      route: `subscriptions/${subscriptionId}`,
      method: 'DELETE',
      onSuccess: (response, message) => {
        const subscriptions = state.data.filter((s) => s.id !== subscriptionId);
        dispatch({ type: 'FETCH_SUCCESS', payload: subscriptions, message });
      },
      onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
    });
  };

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest({
      route: 'subscriptions',
      method: 'GET',
      onSuccess: (subscriptions) => {
        dispatch({ type: 'FETCH_SUCCESS', payload: subscriptions });
      },
      onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
    });
  }, []);

  return (
    <Row className="mb-4">
      <h3 className="d-flex justify-content-between align-items-center mb-4">
        Existing subscriptions
        {state.isLoading && <Spinner animation="border" />}
      </h3>

      <Button as={NavLink} to="new" variant="primary" className="mb-4">Create a new subscription</Button>

      {state.isError && (
        <Alert variant="danger" style={{ whiteSpace: 'pre-wrap' }}>
          {state.error}
        </Alert>
      )}

      {state.message && (
        <Alert variant="success">
          {state.message}
        </Alert>
      )}

      {
        state.data.length
          ? (
            <Table className="text-center" bordered>
              <thead>
                <tr>
                  <th>Customer</th>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Products</th>
                  <th>Comments</th>
                  <th>Active</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  state.data.map((subscription) => (
                    <tr key={subscription.id}>
                      <td>
                        <h5 className="pb-1">
                          {subscription.customer.company_name}
                        </h5>
                        <strong>{subscription.customer.name}</strong>
                        {` (${subscription.customer.email})`}
                        <div className="pt-2">
                          <pre className="mb-0">{subscription.customer.repository}</pre>
                          <pre className="mb-0">{subscription.customer.odoo_url}</pre>
                        </div>
                      </td>
                      <td>{subscription.name}</td>
                      <td>
                        {subscription.price}
                        {' '}
                        EUR
                      </td>
                      <td>
                        {subscription.products.map((product) => (
                          <Badge key={product.id} bg="primary" className="me-2 mb-2 font-size-1">
                            {product.name}
                          </Badge>
                        ))}
                      </td>
                      <td>{subscription.comments}</td>
                      <td>
                        {
                          subscription.is_active
                            ? <BsCheckSquare size="25" />
                            : <BsSquare size="25" />
                        }
                      </td>
                      <td>
                        <Button
                          href={`https://dashboard.stripe.com/subscriptions/${subscription.stripe_id}`}
                          target="_blank"
                          variant="outline-primary"
                          className="me-2 mb-2"
                        >
                          Open in Stripe
                        </Button>

                        <Button
                          as={NavLink}
                          to={`${subscription.id}`}
                          variant="outline-primary"
                          className="me-2 mb-2"
                        >
                          Edit
                        </Button>
                        <Button
                          variant="outline-danger"
                          onClick={() => handleDeleteSubscription(subscription.id)}
                          className="me-2 mb-2"
                        >
                          Delete

                        </Button>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          )
          : (
            <Alert variant="info">
              No subscriptions found
            </Alert>
          )
      }
    </Row>
  );
}

export default SubscriptionsList;
