import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Row, Spinner, Table } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { reducer, sendRequest } from '../../../utils';

const groupBranchesByCustomer = (branches) => {
  const customers = {};

  branches.forEach((branch) => {
    const customerName = `${branch.customer.company_name} (${branch.customer.name})`;

    if (!customers[customerName]) {
      customers[customerName] = [];
    }

    customers[customerName].push(branch);
  });

  return Object.entries(customers);
};

function BranchesList() {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isLoading: true, isError: false,
    },
  );

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest({
      route: 'branches',
      method: 'GET',
      onSuccess: (customers) => {
        dispatch({ type: 'FETCH_SUCCESS', payload: customers });
      },
      onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
    });
  }, []);

  return (
    <Row className="mb-4">
      <h3 className="d-flex justify-content-between align-items-center mb-4">
        Existing branches
        {state.isLoading && <Spinner animation="border" />}
      </h3>

      <Button as={NavLink} to="new" variant="primary" className="mb-4">Create a new branch</Button>

      {state.isError && (
        <Alert variant="danger" style={{ whiteSpace: 'pre-wrap' }}>
          {state.error}
        </Alert>
      )}

      {state.message && (
        <Alert variant="success">
          {state.message}
        </Alert>
      )}

      {
        state.data.length
          ? groupBranchesByCustomer(state.data).map(([customer, branches]) => (
            <CustomerBranches key={customer} customer={customer} branches={branches} />
          ))
          : (
            <Alert variant="info">
              No branches found
            </Alert>
          )
      }
    </Row>
  );
}

function CustomerBranches({ customer, branches }) {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: branches, message: '', error: '', isLoading: true, isError: false,
    },
  );

  const handleDeleteBranch = (branchId) => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest({
      route: `branches/${branchId}`,
      method: 'DELETE',
      onSuccess: (response, message) => {
        const branches = state.data.filter((branch) => branch.id !== branchId);
        dispatch({ type: 'FETCH_SUCCESS', payload: branches, message });
      },
      onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
    });
  };

  return (
    <>
      <h4 className="mb-2">{customer}</h4>

      {state.isError && (
        <Alert variant="danger" style={{ whiteSpace: 'pre-wrap' }}>
          {state.error}
        </Alert>
      )}

      {state.data.length === 0 && (
        <Alert variant="info">
          No branches found
        </Alert>
      )}

      {state.data.length > 0 && (
        <Table className="text-center table-sm mb-4" bordered>
          <thead>
            <tr>
              <th className="w-25">Name</th>
              <th className="w-25">Main Branch</th>
              <th className="w-25">Notes</th>
              <th className="w-25">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
            state.data.map((branch) => (
              <tr key={branch.id}>
                <td>{branch.name}</td>
                <td>{branch.main_branch}</td>
                <td>{branch.notes}</td>
                <td>
                  <Button
                    as={NavLink}
                    to={`${branch.id}`}
                    variant="outline-primary"
                    className="me-2 mb-2"
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outline-danger"
                    onClick={() => handleDeleteBranch(branch.id)}
                    className="me-2 mb-2"
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))
          }
          </tbody>
        </Table>
      )}
    </>
  );
}

CustomerBranches.propTypes = {
  customer: PropTypes.string.isRequired,
  branches: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    main_branch: PropTypes.bool.isRequired,
    notes: PropTypes.string.isRequired,
  })).isRequired,
};

export default BranchesList;
