import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form } from 'react-bootstrap';
import { reducer, sendRequest } from '../../../utils';

function ProductSelector({ defaultValue, disabled, required, onChange, name, label }) {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isLoading: true, isError: false,
    },
  );

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest({
      route: 'products',
      method: 'GET',
      onSuccess: (products) => {
        // Sort by name
        products.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

        dispatch({ type: 'FETCH_SUCCESS', payload: products });
      },
      onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
    });
  }, [defaultValue]);

  return (
    state.isLoading
      ? <p>Loading...</p>
      : (
        <Form.Group as={Col} md="12" className="mb-3" controlId="product">
          <Form.Label>{label}</Form.Label>
          <Form.Select
            aria-label="Select Product"
            name={name}
            onChange={handleChange}
            defaultValue={defaultValue}
            disabled={disabled}
            required={required}
          >
            <option value="">Select Product</option>
            {
              state.data.map(
                (product) => (
                  <option value={product.id} key={product.id}>
                    {product.name}
                  </option>
                ),
              )
            }
          </Form.Select>
        </Form.Group>
      )
  );
}

ProductSelector.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

ProductSelector.defaultProps = {
  defaultValue: null,
  disabled: false,
  required: false,
  onChange: () => {},
};

export default ProductSelector;
