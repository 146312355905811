import React from 'react';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { NavLink, Navigate, useLocation, useNavigate } from 'react-router-dom';

import { login, useAuth, sendRequest, reducer } from '../../utils';

function WebauthnLogin() {
  const location = useLocation();
  const navigate = useNavigate();
  const [logged] = useAuth();

  const [email, setEmail] = React.useState('');

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isLoading: false, isError: false,
    },
  );

  if (logged) {
    return <Navigate to="/" />;
  }

  const onSubmitClick = (e) => {
    e.preventDefault();

    if (!email) {
      dispatch({ type: 'FETCH_FAILURE', error: 'Email is required' });
      return;
    }

    dispatch({ type: 'FETCH_INIT' });

    sendRequest({
      route: 'auth/webauthn/authenticate/start',
      method: 'POST',
      body: { email },
      withAuth: false,
      onSuccess: async (options) => {
        // const options = data;

        options.challenge = Uint8Array.from(window.atob(options.challenge.replace(/-/g, '+').replace(/_/g, '/')), (c) => c.charCodeAt(0));
        options.allowCredentials.forEach((listItem) => {
          listItem.id = Uint8Array.from(window.atob(listItem.id.replace(/-/g, '+').replace(/_/g, '/')), (c) => c.charCodeAt(0));
        });

        const credential = await navigator.credentials.get({ publicKey: options });

        const opts = {
          id: credential.id,
          rawId: window.btoa(String.fromCharCode.apply(null, new Uint8Array(credential.rawId))),
          email,
          response: {
            clientDataJSON: window.btoa(String.fromCharCode.apply(null, new Uint8Array(credential.response.clientDataJSON))),
            authenticatorData: window.btoa(String.fromCharCode.apply(null, new Uint8Array(credential.response.authenticatorData))),
            signature: window.btoa(String.fromCharCode.apply(null, new Uint8Array(credential.response.signature))),
            userHandle: window.btoa(String.fromCharCode.apply(null, new Uint8Array(credential.response.userHandle))),
          },
        };

        sendRequest({
          route: 'auth/webauthn/authenticate/finish',
          method: 'POST',
          body: opts,
          withAuth: false,
          onSuccess: (tokens) => {
            login(tokens);
            const from = location.state?.from || '/';
            navigate(from);
          },
          onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
        });
      },
      onError: async (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
    });
  };

  return (
    <Container className="mt-4">
      <Row className="justify-content-md-center">
        <Col md="6">
          <h4 className="mb-4">Login with Webauthn</h4>
          <Form>
            {state.isError && (
              <Alert variant="danger">
                {state.error}
              </Alert>
            )}

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} />
            </Form.Group>

            <Button variant="primary" type="submit" className="me-2" onClick={onSubmitClick}>Login</Button>
            <Button as={NavLink} to="/login" variant="outline-secondary" end>Back to Regular Login</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default WebauthnLogin;
