import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

function PrivateRoute({ logged, children }) {
  const location = useLocation();

  if (!logged) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children || <Outlet />;
}

PrivateRoute.propTypes = {
  logged: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

PrivateRoute.defaultProps = {
  children: null,
};

export default PrivateRoute;
