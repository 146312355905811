import React from 'react';
import { Alert, Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import ImageUploader from '../../../Common/ImageUploader';
import { reducer, sendRequest } from '../../../utils';

function CreateOrEditProduct() {
  const { productId } = useParams();
  const navigation = useNavigate();

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: {}, message: '', error: '', isLoading: false, isError: false,
    },
  );

  const handleFormSubmit = (e) => {
    e.preventDefault();

    dispatch({ type: 'FETCH_INIT' });
    console.log('submit');

    // Get form content
    const formData = new FormData(e.target);
    const opts = Object.fromEntries(formData);

    opts.versions = opts.versions.split(',').map((i) => i.trim());
    opts.directories = opts.directories.split(',').map((i) => i.trim());

    if (state.data.image) {
      opts.image = state.data.image;
    }

    // Remove latest version dates (this field is computed)
    delete opts.latest_release_dates;

    const route = productId ? `products/${productId}` : 'products';
    const method = productId ? 'PUT' : 'POST';

    sendRequest({
      route,
      method,
      body: opts,
      onSuccess: () => {
        navigation('/admin/products');
      },
      onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
    });
  };

  React.useEffect(() => {
    if (productId) {
      dispatch({ type: 'FETCH_INIT' });

      sendRequest({
        route: `products/${productId}`,
        method: 'GET',
        onSuccess: (product) => {
          dispatch({ type: 'FETCH_SUCCESS', payload: product });
        },
        onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
      });
    }
  }, []);

  const handleImageChange = (image) => {
    dispatch({ type: 'FETCH_SUCCESS', payload: { ...state.data, image } });
  };

  const handleUpdateReleaseDatesClick = () => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest({
      route: `products/${productId}/update-release-dates`,
      method: 'GET',
      onSuccess: (response, message) => {
        dispatch({ type: 'FETCH_SUCCESS', message });
      },
      onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
    });
  };

  return (
    <Row className="mb-4">
      <h3 className="d-flex justify-content-between align-items-center mb-4">
        {productId ? 'Edit product' : 'Add new product'}
        {state.isLoading && <Spinner animation="border" />}
      </h3>

      {state.isError && (
        <Alert variant="danger">
          {state.error}
        </Alert>
      )}

      {state.message && (
        <Alert variant="success">
          {state.message}
        </Alert>
      )}

      <Form onSubmit={handleFormSubmit}>
        <Row className="mb-3">

          <Col sm="12" md="3">
            <Form.Group as={Col} className="d-flex justify-content-center" controlId="image">
              <ImageUploader onImageUpload={handleImageChange} defaultImage={state.data.image} />
            </Form.Group>
          </Col>

          <Col sm="12" md="9">
            <Row>
              <Form.Group as={Col} sm="12" md="6" className="mb-3" controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Enter name"
                  defaultValue={state.data.name}
                  required
                />
              </Form.Group>

              <Form.Group as={Col} sm="12" md="6" className="mb-3" controlId="price">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="number"
                  name="price"
                  placeholder="Enter price"
                  defaultValue={state.data.price}
                  required
                />
              </Form.Group>
            </Row>

            <Form.Group as={Col} className="mb-3" controlId="repository">
              <Form.Label>Repository</Form.Label>
              <Form.Control
                type="text"
                name="repository"
                placeholder="Enter repository"
                defaultValue={state.data.repository}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Form.Group as={Col} className="mb-3" controlId="directories">
            <Form.Label>Directories</Form.Label>
            <Form.Control
              type="text"
              name="directories"
              placeholder="Enter directories with modules (separated by comma)"
              defaultValue={state.data.directories?.join(', ')}
              required
            />
          </Form.Group>

          <Form.Group as={Col} className="mb-3" controlId="versions">
            <Form.Label>Versions</Form.Label>
            <Form.Control
              type="text"
              name="versions"
              placeholder="Enter supported versions (separated by comma)"
              defaultValue={state.data.versions?.join(', ')}
              required
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} className="mb-3" controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="description"
              placeholder="Enter description"
              defaultValue={state.data.description}
              required
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} className="mb-3" controlId="latest_release_dates">
            <Form.Label>Latest Release Dates</Form.Label>
            <Form.Control
              as="textarea"
              rows={7}
              name="latest_release_dates"
              placeholder="Enter latest release dates"
              defaultValue={JSON.stringify(state.data.latest_release_dates, null, 2)}
              readOnly
            />
          </Form.Group>
        </Row>

        <Row>
          <Col>
            <Button variant="primary" type="submit">Save</Button>
            <Button variant="outline-secondary" className="ms-2" onClick={handleUpdateReleaseDatesClick}>Update Release Dates</Button>
          </Col>
        </Row>
      </Form>
    </Row>
  );
}

export default CreateOrEditProduct;
