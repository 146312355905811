import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'react-bootstrap';
import { reducer, sendRequest } from '../../../utils';

function CustomerAndBranchSelector({ defaultCustomerId, defaultBranchId, disabled, required }) {
  const [selectedCustomer, setSelectedCustomer] = React.useState(null);
  const [selectedBranchId, setSelectedBranchId] = React.useState(defaultBranchId);

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isLoading: true, isError: false,
    },
  );

  const handleCustomerChange = (e) => {
    const customerId = Number(e.target.value);
    const selectedCustomer = state.data.filter((customer) => customer.id === customerId)[0];
    setSelectedCustomer(selectedCustomer);
  };

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest({
      route: 'customers',
      method: 'GET',
      onSuccess: (customers) => {
        if (defaultCustomerId) {
          const selectedCustomer = customers.filter((customer) => customer.id === defaultCustomerId)[0];
          setSelectedCustomer(selectedCustomer);
        }
        dispatch({ type: 'FETCH_SUCCESS', payload: customers });
      },
      onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
    });
  }, [defaultCustomerId]);

  React.useEffect(() => {
    setSelectedBranchId(defaultBranchId);
  }, [defaultBranchId]);

  return (
    state.isLoading
      ? <p>Loading...</p>
      : (
        <Row>
          <Form.Group as={Col} md="6" className="mb-3" controlId="customer_id">
            <Form.Label>Customer</Form.Label>
            <Form.Select
              name="customer_id"
              aria-label="Select Customer"
              onChange={handleCustomerChange}
              defaultValue={defaultCustomerId}
              required={required}
              disabled={disabled}
            >
              <option value="">Select Customer</option>
              {
                state.data.map(
                  (customer) => (
                    <option value={customer.id} key={customer.id}>
                      {customer.company_name}
                      {' '}
                      (
                      {customer.name}
                      )
                    </option>
                  ),
                )
              }
            </Form.Select>
          </Form.Group>

          <Form.Group as={Col} md="6" className="mb-3" controlId="branch_id">
            <Form.Label>Branch</Form.Label>
            <Form.Select
              name="branch_id"
              aria-label="Select Branch"
              value={selectedBranchId || ''}
              onChange={(e) => setSelectedBranchId(Number(e.target.value))}
              required={required}
            >
              <option value="">Select Branch</option>
              {
                selectedCustomer && selectedCustomer.branches.map(
                  (branch) => (
                    <option value={branch.id} key={branch.id}>{`${branch.name} (main branch: ${branch.main_branch || '<not-set>'})`}</option>
                  ),
                )
              }
            </Form.Select>
          </Form.Group>
        </Row>
      )
  );
}

CustomerAndBranchSelector.propTypes = {
  defaultCustomerId: PropTypes.number,
  defaultBranchId: PropTypes.number,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

CustomerAndBranchSelector.defaultProps = {
  defaultCustomerId: null,
  defaultBranchId: null,
  disabled: false,
  required: false,
};

export default CustomerAndBranchSelector;
