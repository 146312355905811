import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'react-bootstrap';
import { reducer, sendRequest } from '../../../utils';

function ProductAndVersionSelector({ defaultProductId, defaultVersion, disabled, required }) {
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [selectedVersion, setSelectedVersion] = React.useState(defaultVersion);

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isLoading: true, isError: false,
    },
  );

  const handleProductChange = (e) => {
    const productId = Number(e.target.value);
    const selectedProduct = state.data.filter((product) => product.id === productId)[0];
    setSelectedProduct(selectedProduct);
  };

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest({
      route: 'products',
      method: 'GET',
      onSuccess: (products) => {
        if (defaultProductId) {
          const selectedProduct = products.filter((product) => product.id === defaultProductId)[0];
          setSelectedProduct(selectedProduct);
        }
        dispatch({ type: 'FETCH_SUCCESS', payload: products });
      },
      onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
    });
  }, [defaultProductId]);

  React.useEffect(() => {
    setSelectedVersion(defaultVersion);
  }, [defaultVersion]);

  return (
    state.isLoading
      ? <p>Loading...</p>
      : (
        <Row>
          <Form.Group as={Col} md="6" className="mb-3" controlId="product">
            <Form.Label>Product</Form.Label>
            <Form.Select
              name="product_id"
              aria-label="Select Product"
              onChange={handleProductChange}
              defaultValue={defaultProductId}
              disabled={disabled}
              required={required}
            >
              <option value="">Select Product</option>
              {
                state.data.map(
                  (product) => <option value={product.id} key={product.id}>{product.name}</option>,
                )
              }
            </Form.Select>
          </Form.Group>

          <Form.Group as={Col} md="6" className="mb-3" controlId="version">
            <Form.Label>Version</Form.Label>
            <Form.Select
              name="version"
              aria-label="Select Version"
              // Use value instead of defaultValue to avoid issue with loading default value
              value={selectedVersion}
              onChange={(e) => setSelectedVersion(e.target.value)}
              required={required}
            >
              <option value="">Select Version</option>
              {
                selectedProduct && selectedProduct.versions.map(
                  (version) => <option value={version} key={version}>{version}</option>,
                )
              }
            </Form.Select>
          </Form.Group>
        </Row>
      )
  );
}

ProductAndVersionSelector.propTypes = {
  defaultProductId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultVersion: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

ProductAndVersionSelector.defaultProps = {
  defaultProductId: null,
  defaultVersion: '',
  disabled: false,
  required: false,
};

export default ProductAndVersionSelector;
