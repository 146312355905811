import React from 'react';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { NavLink, Navigate, useLocation, useNavigate } from 'react-router-dom';

import { login, useAuth, sendRequest, reducer } from '../../utils';

function Login() {
  const location = useLocation();
  const navigate = useNavigate();
  const [logged] = useAuth();

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isLoading: false, isError: false,
    },
  );

  if (logged) {
    return <Navigate to="/" />;
  }

  const onSubmitClick = (e) => {
    e.preventDefault();
    const opts = {
      email,
      password,
    };

    sendRequest({
      route: 'auth/login',
      method: 'POST',
      body: opts,
      withAuth: false,
      onSuccess: (tokens) => {
        login(tokens);
        const from = location.state?.from || '/';
        navigate(from);
      },
      onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
    });
  };

  return (
    <Container className="mt-4">
      <Row className="justify-content-md-center">
        <Col md="6">
          <h4 className="mb-4">Login</h4>
          <Form>
            {state.isError && (
              <Alert variant="danger">
                {state.error}
              </Alert>
            )}

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
            </Form.Group>

            <Button variant="primary" type="submit" className="me-2" onClick={onSubmitClick}>Login</Button>
            <Button as={NavLink} to="/login/webauthn" variant="outline-secondary">Login with Webauthn</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
