import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

function Home() {
  return (
    <>
      <h4 className="mb-3">Subscriptions</h4>
      <Row xs={1} md={2} className="g-4">
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Customers</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">CRUD for customers</Card.Subtitle>
              <Card.Text>
                View, create, edit or delete customers
              </Card.Text>
              <Button as={NavLink} variant="outline-primary" to="customers">Go to Customers</Button>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Products</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">CRUD for products</Card.Subtitle>
              <Card.Text>
                View, create, edit or delete products
              </Card.Text>
              <Button as={NavLink} variant="outline-primary" to="products">Go to Products</Button>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Subscriptions</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">CRUD for subscriptions</Card.Subtitle>
              <Card.Text>
                View, create, edit or delete subscriptions
              </Card.Text>
              <Button as={NavLink} variant="outline-primary" to="subscriptions">Go to Subscriptions</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <hr className="mt-5 mb-5" />

      <h4 className="mb-3">Autopush</h4>
      <Row xs={1} md={2} className="g-4">
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Autopush Rules</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">CRUD for autopush rules</Card.Subtitle>
              <Card.Text>
                View, create, edit or delete rules
              </Card.Text>
              <Button as={NavLink} variant="outline-primary" to="autopush">Go to Rules</Button>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Branches</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">CRUD for branches</Card.Subtitle>
              <Card.Text>
                View, create, edit or delete branches
              </Card.Text>
              <Button as={NavLink} variant="outline-primary" to="branches">Go to Branches</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <hr className="mt-5 mb-5" />

      <h4 className="mb-3">Support</h4>
      <Row xs={1} md={2} className="g-4">
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Support Tickets</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">CRUD for support tickets</Card.Subtitle>
              <Card.Text>
                View, create, edit or delete tickets
              </Card.Text>
              <Button as={NavLink} variant="outline-primary" to="support-tickets">Go to Tickets</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Home;
