import React from 'react';
import { Alert, Button, Row, Spinner, Table } from 'react-bootstrap';

import { reducer, sendRequest } from '../../../utils';

function SupportTicketsList() {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isLoading: true, isError: false,
    },
  );

  const handleDeleteTicket = (ticketId) => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest({
      route: `support-tickets/${ticketId}`,
      method: 'DELETE',
      onSuccess: (response, message) => {
        const tickets = state.data.filter((s) => s.id !== ticketId);
        dispatch({ type: 'FETCH_SUCCESS', payload: tickets, message });
      },
      onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
    });
  };

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest({
      route: 'support-tickets',
      method: 'GET',
      onSuccess: (tickets) => {
        dispatch({ type: 'FETCH_SUCCESS', payload: tickets });
      },
      onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
    });
  }, []);

  return (
    <Row className="mb-4">
      <h3 className="d-flex justify-content-between align-items-center mb-4">
        Support Tickets
        {state.isLoading && <Spinner animation="border" />}
      </h3>

      {state.isError && (
        <Alert variant="danger" style={{ whiteSpace: 'pre-wrap' }}>
          {state.error}
        </Alert>
      )}

      {state.message && (
        <Alert variant="success">
          {state.message}
        </Alert>
      )}

      {
        state.data.length
          ? (
            <Table className="text-center" bordered>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Question</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  state.data.map((ticket) => (
                    <tr key={ticket.id}>
                      <td>{ticket.name}</td>
                      <td>{ticket.email}</td>
                      <td>{ticket.question}</td>
                      <td>
                        <Button
                          variant="outline-danger"
                          onClick={() => handleDeleteTicket(ticket.id)}
                          className="me-2 mb-2"
                        >
                          Delete

                        </Button>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          )
          : (
            <Alert variant="info">
              No support tickets found
            </Alert>
          )
      }
    </Row>
  );
}

export default SupportTicketsList;
